import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import logo from '../assets/images/logo.png';
import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <img src={logo} alt={config.heading} className="image-sizer fit" /><br />
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">Explore</a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">Learn More</a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Real Estate Wealth, Asset & Portfolio Management Solutions for HNW Individuals & Family Offices  
          </h2>
          <p>
            From city blocks, iconic buildings, and landmarks to exclusive islands, vacation homes, oppulent apartments, penthouses, luxury retreats, lake homes, ski chalets, private villas... we have it all.
          </p>
        </header>
        {/* <ul className="icons major">
          <li>
            <span className="icon fa-gem major style1">
              <span className="label">Wealth</span>
            </span>
          </li>
          <li>
            <span className="icon fa-heart major style2">
              <span className="label">Ipsum</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-code major style3">
              <span className="label">Dolor</span>
            </span>
          </li>
        </ul> */}
      </div>
    </section>

    <section id="two" className="wrapper alt style4">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>
            ULTRA EXCLUSIVE OFF MARKET LISTINGS
          </h2>
          <p>
          The HNW Realty international network opens up access to exclusive opportunities in both commercial real estate and luxury residential real estate globally.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>
            A FULLY VETTED PRIVATE NETWORK
          </h2>
          <p>
            Participants in the HNW network are full vetted, to assure the highest quality of buyers, sellers, and transactional intermediaries throughout the world.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>
            WHITE GLOVES FOR A SELECT CLIENTELE
          </h2>
          <p>
            Respect, privacy and care. We pride ourselves on the unparalleled level of service we bring to the fore while working on behalf our esteemed clients and partners.
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>PROTECT YOUR WEALTH IN SMART REAL ESTATE INVESTMENTS</h2>
          <p>
            Our team of seasoned real estate professionals discover and acquire the right investments for your portfolio
          </p>
        </header>
        {/* <ul className="features">
          <li className="icon fa-paper-plane">
            <h3>Consultation</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon solid fa-laptop">
            <h3>Strategy</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon solid fa-code">
            <h3>Residential</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon solid fa-headphones-alt">
            <h3>Commercial</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Track Record</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon fa-flag">
            <h3>Family Offices</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
        </ul> */}
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>HNW REALTY CORP.</h2>
          <p>
            Real Estate Wealth, Asset & Portfolio Management Solutions for HNW Individuals & Family Offices
          </p>
        </header>
        <ul className="actions special stacked">
          {/* <li>
            <a href="/#" className="button fit primary">
              Request Info
            </a>
          </li> */}
          <li>
            <Link to="/Contact" className="button fit">
              Request Info
            </Link>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
